import React from 'react';
import './Footer.css';
import white_logo from '../img/white_logo.png';
import footerlogo from '../img/logo.png';

const Footer = ({ handleOpen }) => {
    const openModal = (event, title, body) => {
        event.preventDefault();
        const scrollPos = window.scrollY; // 현재 스크롤 위치 저장
        handleOpen(title, body, scrollPos);
    };

    return (
        <footer className="footer">
            <div className="footer-wrap">
                <div className="left">
                    <div className="logowrap">
                        <a href="/"><img src={white_logo} alt="logo"/></a>
                        <p>스마트안전관리시스템</p>
                        <p className="slogan eng">Contech Future Leader</p>
                    </div>
                </div>
                <div className="middle">
                    <ul>
                        <li><span>주소<i className='xi-map-o'></i></span><p>서울특별시 강남구 테헤란로 534 (대치동) 글라스타워 21 세이프디 주식회사</p></li>
                        <li><span>Email<i className='xi-plus-thin'></i></span><p className="eng">sales@safed.co.kr</p></li>
                        <li><span>Tel<i className='xi-check-thin'></i></span><p className="eng">02-6748-0835 <br></br>02-6748-0836</p></li>
                    </ul>
                </div>
                <div className="right">
                <div>
    <h2 className="title eng">download</h2>
    <a href="https://apps.apple.com/kr/app/%EC%84%B8%EC%9D%B4%ED%94%84%EB%94%94-safed-%EC%8A%A4%EB%A7%88%ED%8A%B8-%EC%95%88%EC%A0%84%EA%B4%80%EB%A6%AC-%EC%8B%9C%EC%8A%A4%ED%85%9C/id1542123112" target="_blank" rel="noopener noreferrer">
        <button>아이폰 <i className="xi-download"></i></button>
    </a>
    <a href="https://play.google.com/store/apps/details?id=kr.co.yido.safeD" target="_blank" rel="noopener noreferrer">
        <button>안드로이드<i className="xi-download"></i></button>
    </a>
</div>

                </div>
            </div>
            <div className="footer_bottom">
                <p className="copyright eng"><span>&copy; 2023 SafeD. All rights reserved.</span></p>
                <ul className="m_none">
                    <li><a href="/Privacy-Policy">개인정보취급방침</a></li>
                    <li><a href="/TermsOfService">사이트 이용약관</a></li>
                    <li><a href="/inquiries">온라인 문의</a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
